import React, { useRef } from "react";
import SevenDaysBanner from "../components/SevenDaysBanner";
import DefaultHero from "../components/DefaultHero";
import Footer from "../components/Footer";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import Navbar from "../components/navbar";
import PromoBar from "../components/PromoBar";
let category;

class KarriereCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        if (typeof window != "undefined") {
            const urlParams = new URLSearchParams(window.location.search);
            category = urlParams.get("category");
        }
    }
    onLoad = () => {
        window.addEventListener("message", this.handleEvent);
    };

    componentWillUnmount() {
        window.removeEventListener("message", this.handleEvent);
    }

    handleEvent(event) {
        var iframe = document.querySelector("#personio-iframe");
        var data = event.data[1];
        switch (event.data[0]) {
            case "setHeight":
                iframe.style.height = data + "px";
                break;
        }
    }

    render() {
        setTimeout(() => {
            if (typeof window !== `undefined`) {
                const jobs = document.getElementById("jobsId");
                if (jobs) {
                    jobs.scrollIntoView();
                }
            }
        }, 2000);
        return (
            <StaticQuery
                query={graphql`
                    query Karrier2eQuery {
                        site {
                            siteMetadata {
                                title
                                image
                                image768
                                image1024
                                image1216
                                image1408
                            }
                        }
                    }
                `}
                render={(data) => {
                    const image =
                        "meinalarm24-team-karriere-wir-suchen-dich-desktop-V4.jpg";
                    const contextData = {
                        title: data.site.siteMetadata.title,
                        image: "/img/hero/" + image,
                        image768:
                            "/img/hero/meinalarm24-team-karriere-wir-suchen-dich-mobile-V4.jpg",
                        image1024:
                            "/img/hero/meinalarm24-team-karriere-wir-suchen-dich-mobile-V4.jpg",
                        image1216: "/img/hero/" + image,
                        image1408: "/img/hero/" + image,
                        defaultCssClass: "karriere-hero",
                        showPriceCalculator: false,
                        showHeroMobile: false,
                        showBottom: false,
                    };

                    return (
                        <React.Fragment>
                            <header className="header">
                                <Helmet>
                                    <html className="has-navbar-fixed-top" />
                                    <title>
                                        Bewirb dich jetzt bei MeinAlarm24!
                                    </title>
                                    <meta
                                        name="description"
                                        content="Vom Start-up zum Marktführer: MeinAlarm24 krempelt den Markt für Scherheitstechnik um. Sei dabei und bewirb dich jetzt auf eine von vielen offenen Stellen."
                                    />
                                    <meta name="keywords" content="" />
                                    <meta
                                        property="og:type"
                                        content="article"
                                    />
                                    <meta
                                        property="og:title"
                                        content="Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24"
                                    />
                                    <meta
                                        property="og:description"
                                        content="Vom Start-up zum Marktführer: MeinAlarm24 krempelt den Markt für Scherheitstechnik um. Sei dabei und bewirb dich jetzt auf eine von vielen offenen Stellen."
                                    />
                                    <meta property="og:image" content={image} />
                                </Helmet>
                                <PromoBar context={contextData} />
                                <Navbar context={contextData} />
                            </header>
                            <div className="karriere-site2">
                                <DefaultHero context={contextData} />
                                <section className="karriere-site " id="jobsId">
                                    <div className="container layout">
                                        <div className="content columns">
                                            <div className="column">
                                                <h1>
                                                    Deine Karriere bei
                                                    MeinAlarm24
                                                </h1>
                                                <p>
                                                    Mit Dir gemeinsam zu
                                                    Deutschlands Nummer 1!
                                                </p>
                                                <p>
                                                    Direkt am Kunden mit
                                                    modernster
                                                    Sicherheitstechnik –
                                                    MeinAlarm24 ist die
                                                    schnellst wachsende Firma im
                                                    Markt. Unser Ziel ist es die
                                                    Nummer 1 für elektronische
                                                    Sicherheitstechnik in
                                                    Deutschland zu werden.
                                                </p>
                                                <p>
                                                    Um den nächsten Gang
                                                    einzulegen, wollen wir per
                                                    sofort folgende Stellen
                                                    besetzen:
                                                </p>
                                                <iframe
                                                    id="personio-iframe"
                                                    onLoad={this.onLoad}
                                                    ref={(container) => {
                                                        this.container =
                                                            container;
                                                    }}
                                                    src={`https://meinalarm24.jobs.personio.de/#${category}`}
                                                    width="100%"
                                                    style={{ border: "none" }}
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <SevenDaysBanner />
                                <Footer />
                            </div>
                        </React.Fragment>
                    );
                }}
            />
        );
    }
}

export default KarriereCalculator;
